
.jobs_container{
    display: flex;
    flex-wrap: wrap;
}

.jobs{
    width: 295px;
    height: 220px;
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 1rem;
    margin: 1rem auto ;
}
@media screen and (max-width: 600px) {

    .jobs {
        width: inherit;
    }
}
.jobs a{
    text-decoration: underline;
}

.logoJobs{
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 10%;
    margin: 0 auto 1em;
}


.logoGame{
    padding-top: 2rem;
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 10%;
    margin: 0 auto 1em;
}


