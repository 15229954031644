.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    min-width: fit-content;
    width: 38%;
    padding-bottom: 5rem;
}

.contact_option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1rem;
    text-align: center;
    transition: var(--transition);

}
.contact_option:hover {
    background: transparent;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);


}
.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
#contact  a{
    color: var(--color-black);
}
#contact{
    background: var(--color-bg-ods);
}
