
html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg:#eef1f3;
    --color-bg-variant:#ccd6db;
    --color-bg-ods:#dde4e7;
    --color-primary: #4a4857;
    --color-primary-variant: rgba(77, 181, 255, 04);
    --color-black: #1F1F28;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 400ms ease;
    --container-width-lg: 70%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}


body {
    font-family: sans-serif;
    background: var(--color-bg);
    color: var(--color-black);
    line-height: 1.7;
}

/* GENERAL STYLES */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}
h2 {
    padding-top: 2em;
    padding-bottom: 2em;
}

section {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-black);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-black);
}

a {
    color: var(--color-primary);
    transition: var(--transition);

}
p  a{
    text-decoration: underline;
}
a:hover {
    color: var(--color-black);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-black);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* MEDIA QUERIES (MEDIUM DEVICES)*/
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

}

/* MEDIA QUERIES (SMALL DEVICES)*/
@media screen and (max-width: 600px) {

    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}
